@use "sass:color";
@use "src/styles/responsive" as r;
@use "src/styles/typography";
@use "src/styles/colors";

:root {
    --deco-margin: 4rem;

    @include r.lg {
        --deco-margin: 3rem;
    }

    @include r.md {
        --deco-margin: 0;
    }
}

html,
body {
    height: 100%;
}

body {
    font: typography.$font-body;
}

h1,
h2,
h3,
h4 {
    line-height: 1em;
    letter-spacing: -0.03em;
    margin: 0.5em 0;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font: typography.$font-h1;

    @include r.md {
        font-size: 2rem;
    }
}

h2 {
    font: typography.$font-h2;

    @include r.md {
        font-size: 1.5rem;
    }
}

h3 {
    font: typography.$font-h3;

    @include r.md {
        font-size: 1rem;
    }
}

h4 {
    font: typography.$font-h4;
}

p,
li {
    line-height: 1.5;
}

a {
    color: colors.$primary-color;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom-color: colors.$primary-color;
    }
}

img {
    display: block;
}
