// Space Grotesk
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300 700;
    src: url('src/assets/fonts/SpaceGrotesk-VariableFont_wght.woff2') format('woff2-variations');
}

// Gilroy
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    src: url('src/assets/fonts/Gilroy-Light.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src: url('src/assets/fonts/Gilroy-ExtraBold.otf');
}

$primary-font-family: 'Gilroy', sans-serif;
$secondary-font-family: 'Space Grotesk', sans-serif;

$font-body: 500 1rem $secondary-font-family;
$font-button: 500 1rem $secondary-font-family;
$font-h1: 800 2.5rem $primary-font-family;
$font-h2: 800 2rem $primary-font-family;
$font-h3: 800 1.25rem $primary-font-family;
$font-h4: 800 1rem $primary-font-family;
